import React from "react";

export const CertificationsList = () => {
    return (
        <section className="portfolio-block skills" style={{
            background: 'black',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'200\' height=\'200\'%3E%3Cpath d=\'M 0 0 L 200 0 L 200 200 L 0 200 Z\' fill=\'none\' stroke=\'none\' /%3E%3Ccircle cx=\'50\' cy=\'50\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'150\' cy=\'100\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'100\' cy=\'150\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'25\' cy=\'175\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'175\' cy=\'25\' r=\'1\' fill=\'%23FFD700\' /%3E%3C/svg%3E")',
                opacity: 0.2
            }} />
            <div className="container">
                <div className="heading">
                    <h2 className="text-center" style={{
                        color: '#FFD700',
                        fontWeight: 'bold',
                        padding: '2rem 0',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
                    }}>Certifications</h2>
                </div>
                <div className="row justify-content-center p-5">
                    {[
                        "fb719115-8898-4a07-8416-93e9b9f7daf2",
                        "ffbe36c9-7932-413c-90c5-77d7aa7fd75a",
                        "5481c3bf-5802-4bae-8e90-712c0fb94c49",
                        "da198a25-1f73-4b4b-bb5d-9d44450a5a12",
                        "779cd9d5-2d4d-47ed-ac31-1d130b7f3170",
                        "90d4d136-b431-4a0c-bdf0-8b26846727e1"
                    ].map((badgeId, index) => (
                        <div key={index} className="col-md-6 col-lg-3 mb-4">
                            <div style={{
                                background: 'rgba(0, 0, 0, 0.6)',
                                padding: '10px',
                                borderRadius: '15px',
                                boxShadow: '0 4px 8px rgba(255, 215, 0, 0.3), 0 0 20px rgba(255, 215, 0, 0.1)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                ':hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0 6px 12px rgba(255, 215, 0, 0.4), 0 0 25px rgba(255, 215, 0, 0.2)'
                                }
                            }}>
                                <iframe
                                    title={`Certification ${index + 1}`}
                                    name="acclaim-badge"
                                    allowTransparency={true}
                                    frameBorder="0"
                                    id={`embedded-badge-${badgeId}`}
                                    scrolling="no"
                                    src={`https://www.credly.com/embedded_badge/${badgeId}`}
                                    style={{
                                        width: '100%',
                                        height: '245px',
                                        maxWidth: '280px',
                                        border: '2px solid #FFD700',
                                        borderRadius: '10px',
                                    }}
                                ></iframe>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}