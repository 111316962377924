import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {NavBar} from "./Components/NavBar";
import {HomePage} from "./Pages/HomePage";
import {ProjectsPage} from "./Pages/ProjectsPage";
import {FooterBar} from "./Components/FooterBar";
import ContactPage from "./Pages/ContactPage";
import AuthPage from './Pages/AuthPage';
import Dashboard from './Pages/DashboardPage';


const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
    },
    {
        path: "projects",
        element: <ProjectsPage/>,
    },
    {
        path: "contact",
        element: <ContactPage/>,
    },
    {
        path: "login",
        element: <AuthPage/>,
    },
    {
        path: "dashboard",
        element: <Dashboard/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <NavBar></NavBar>
      <RouterProvider router={router} />
      <FooterBar></FooterBar>
  </React.StrictMode>
);



