import React, { useEffect, useRef } from 'react';
import { CertificationsList } from "../Components/CertificationsList";
import { AboutMeSection } from "../Components/AboutMeSection";

export const HomePage = () => {
    //background animation
    const canvasRef = useRef(null);
    const starsRef = useRef([]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            initStars();
        };

        const initStars = () => {
            starsRef.current = [];
            for (let i = 0; i < 200; i++) {
                starsRef.current.push({
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    size: Math.random() * 2,
                    speed: Math.random() * 0.5
                });
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        const animateStars = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'rgba(173, 216, 230, 0.5)';  // Light blue color for stars
            starsRef.current.forEach(star => {
                ctx.beginPath();
                ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
                ctx.fill();
                star.y += star.speed;
                if (star.y > canvas.height) star.y = 0;
            });
            animationFrameId = requestAnimationFrame(animateStars);
        };

        animateStars();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return (
        <>
            <link href="https://fonts.googleapis.com/css2?family=Russo+One&display=swap" rel="stylesheet" />
            <main style={{
                background: 'linear-gradient(to bottom, #000000, #0a192f)',
                minHeight: '100vh',
                position: 'relative',
                overflow: 'hidden',
                fontFamily: "'EB Garamond', serif"
            }}>
                <canvas ref={canvasRef} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                <div style={{
                    position: 'relative',
                    zIndex: 1,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '0 10%',
                    color: '#64ffda'  // Light cyan color for text
                }}>
                    <h2 style={{color: '#8892b0', marginBottom: '1rem'}}>Welcome to my Portfolio</h2>
                    <h1 style={{
                        fontFamily: "'Russo One', sans-serif",
                        fontSize: '4rem',
                        marginBottom: '1rem',
                        color: '#64ffda',
                        textShadow: `
                            -1px -1px 0 #ccd6f6,  
                            1px -1px 0 #ccd6f6,
                            -1px 1px 0 #ccd6f6,
                            1px 1px 0 #ccd6f6,
                            0 0 10px rgba(100, 255, 218, 0.7)
                        `
                    }}>
                        Hi! I'm Jordan Carter
                    </h1>
                    <p style={{maxWidth: '600px', marginBottom: '2rem', color: '#8892b0'}}>
                        Aspiring developer with a passion for creating innovative solutions and pushing the boundaries of technology.
                    </p>
                    <a href="/contact" style={{
                        padding: '10px 20px',
                        backgroundColor: 'rgba(100, 255, 218, 0.1)',
                        color: '#64ffda',
                        textDecoration: 'none',
                        borderRadius: '25px',
                        border: '2px solid #64ffda',
                        transition: 'all 0.3s ease'
                    }}>
                        Let's Connect
                    </a>
                </div>
                <CertificationsList />
                <AboutMeSection />
            </main>
        </>
    );
};