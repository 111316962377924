import React from 'react';
import { FaGithub } from 'react-icons/fa';

const ProjectCard = ({ title, description, githubLink, tags }) => {
    return (
        <div className="card h-100 shadow-sm hover-shadow transition" style={{
            backgroundColor: '#1E1E1E',
            borderColor: '#333'
        }}>
            <div className="card-body d-flex flex-column">
                <h5 className="card-title" style={{ color: '#FFD700' }}>{title}</h5>
                <p className="card-text flex-grow-1" style={{ color: '#B0B0B0' }}>{description}</p>
                <div className="mt-2 mb-3">
                    {tags.map((tag, index) => (
                        <span key={index} className="badge me-1 mb-1" style={{
                            backgroundColor: '#333',
                            color: '#FFA500'
                        }}>
                            {tag}
                        </span>
                    ))}
                </div>
                <a
                    className={`btn btn-sm w-100 ${githubLink ? 'btn-outline-info' : 'btn-outline-secondary'}`}
                    href={githubLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!githubLink}
                    style={{
                        borderColor: githubLink ? '#17a2b8' : '#6c757d',
                        color: githubLink ? '#17a2b8' : '#6c757d'
                    }}
                >
                    <FaGithub className="me-2" />
                    {githubLink ? "View on GitHub" : "Not available yet"}
                </a>
            </div>
        </div>
    );
};

export default ProjectCard;