import React, { useState, useEffect } from 'react';
import ProjectCard from "../Components/ProjectCard";

export const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch projects from GitHub API
        const fetchProjects = async () => {
            try {
                const response = await fetch('https://api.github.com/users/1-TB/repos');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                const projectsData = await Promise.all(data.map(async (repo) => {
                    const languagesResponse = await fetch(repo.languages_url);
                    const languages = await languagesResponse.json();

                    return {
                        title: repo.name,
                        description: repo.description || "No description available",
                        githubLink: repo.html_url,
                        tags: Object.keys(languages)
                    };
                }));

                setProjects(projectsData);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    // Display loading message while fetching projects
    if (loading) return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#121212',
            color: '#e0e0e0'
        }}>
            Loading projects...
        </div>
    );

    if (error) return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#121212',
            color: '#e0e0e0'
        }}>
            Error: {error}
        </div>
    );

    return (
        <main style={{
            backgroundColor: '#121212',
            color: '#e0e0e0',
            minHeight: '100vh',
            padding: '2rem 0'
        }}>
            <section className="container">
                <h2 style={{
                    textAlign: 'center',
                    marginBottom: '2rem',
                    color: '#FFD700'
                }}>
                    Projects
                </h2>
                <div className="row g-4">
                    {/* Display projects */}
                    {projects.map((project, index) => (
                        <div key={index} className="col-md-6 col-lg-4">
                            <ProjectCard
                                title={project.title}
                                description={project.description}
                                githubLink={project.githubLink}
                                tags={project.tags}
                            />
                        </div>
                    ))}
                </div>
            </section>
        </main>
    );
};