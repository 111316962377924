import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Nav, Table, Button, Form, Modal, Badge, Alert } from 'react-bootstrap';
import { PERMISSIONS } from '../constants';

const API_BASE_URL = 'https://resumebackend.nsaicu.workers.dev/api';

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('profile');
  const [userProfile, setUserProfile] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editForm, setEditForm] = useState({
    username: '',
    email: '',
    role: '',
    isActive: true
  });

  // Separate useEffect for initial profile fetch
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchProfile();
    }
  }, []);

  // Separate useEffect for users fetch that depends on profile permissions
  useEffect(() => {
    if (userProfile?.permissions?.[PERMISSIONS.MANAGE_USERS]) {
      fetchUsers();
    }
  }, [userProfile]);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      console.log('Fetching profile with token:', token); // Debug log
      
      const response = await fetch(`${API_BASE_URL}/profile`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Profile data:', data); // Debug log
      setUserProfile(data);
    } catch (error) {
      console.error('Profile fetch error:', error); // Debug log
      setError('Failed to fetch profile: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users data:', data); // Debug log
      setUsers(data);
    } catch (error) {
      console.error('Users fetch error:', error); // Debug log
      setError('Failed to fetch users: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editForm)
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      await fetchProfile();
      setShowUserModal(false);
    } catch (error) {
      setError('Failed to update profile: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/users/${selectedUser._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editForm)
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      await fetchUsers();
      setShowUserModal(false);
    } catch (error) {
      setError('Failed to update user: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditForm({
      username: user.username,
      email: user.email,
      role: user.role || 'user',
      isActive: user.isActive !== false
    });
    setShowUserModal(true);
  };

  const renderProfile = () => (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Profile Information</h4>
          <Button 
            variant="primary" 
            size="sm"
            onClick={() => handleEditUser(userProfile)}
            disabled={loading}
          >
            Edit Profile
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : userProfile ? (
          <Row>
            <Col md={6}>
              <p><strong>Username:</strong> {userProfile.username}</p>
              <p><strong>Email:</strong> {userProfile.email}</p>
              <p><strong>Role:</strong> {userProfile.role}</p>
              <p><strong>Last Login:</strong> {userProfile.lastLogin ? new Date(userProfile.lastLogin).toLocaleString() : 'Never'}</p>
            </Col>
            <Col md={6}>
              <h5>Permissions:</h5>
              {userProfile.permissions && Object.entries(userProfile.permissions).map(([key, value]) => (
                <div key={key} className="mb-2">
                  <Badge bg={value ? 'success' : 'secondary'}>
                    {key}: {value ? 'Yes' : 'No'}
                  </Badge>
                </div>
              ))}
            </Col>
          </Row>
        ) : (
          <div className="text-center py-4">No profile data available</div>
        )}
      </Card.Body>
    </Card>
  );

  const renderUsers = () => (
    <Card>
      <Card.Header>
        <h4 className="mb-0">User Management</h4>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : (
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Last Login</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <Badge bg={user.isActive ? 'success' : 'danger'}>
                      {user.isActive ? 'Active' : 'Inactive'}
                    </Badge>
                  </td>
                  <td>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never'}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEditUser(user)}
                      disabled={loading}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );

  const renderSettings = () => (
    <Card>
      <Card.Header>
        <h4 className="mb-0">Account Settings</h4>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Email Notifications</Form.Label>
            <Form.Check 
              type="switch"
              id="notification-switch"
              label="Receive email notifications"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Two-Factor Authentication</Form.Label>
            <Form.Check 
              type="switch"
              id="2fa-switch"
              label="Enable 2FA"
            />
          </Form.Group>
          <Button variant="primary">Save Settings</Button>
        </Form>
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid className="py-4">
      <Row>
        <Col md={3}>
          <Card className="mb-4">
            <Card.Header>
              <h4 className="mb-0">Dashboard</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <Nav className="flex-column">
                <Nav.Link 
                  className={`px-3 py-2 ${activeSection === 'profile' ? 'bg-light' : ''}`}
                  onClick={() => setActiveSection('profile')}
                >
                  Profile
                </Nav.Link>
                {userProfile?.permissions?.[PERMISSIONS.MANAGE_USERS] && (
                  <Nav.Link 
                    className={`px-3 py-2 ${activeSection === 'users' ? 'bg-light' : ''}`}
                    onClick={() => setActiveSection('users')}
                  >
                    User Management
                  </Nav.Link>
                )}
                {userProfile?.permissions?.[PERMISSIONS.EDIT_SETTINGS] && (
                  <Nav.Link 
                    className={`px-3 py-2 ${activeSection === 'settings' ? 'bg-light' : ''}`}
                    onClick={() => setActiveSection('settings')}
                  >
                    Settings
                  </Nav.Link>
                )}
              </Nav>
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          {activeSection === 'profile' && renderProfile()}
          {activeSection === 'users' && renderUsers()}
          {activeSection === 'settings' && renderSettings()}
        </Col>
      </Row>

      {/* Edit Modal */}
      <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser ? 'Edit User' : 'Edit Profile'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={selectedUser ? handleUserUpdate : handleProfileUpdate}>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={editForm.username}
                onChange={(e) => setEditForm({...editForm, username: e.target.value})}
                disabled={!userProfile?.permissions?.[PERMISSIONS.MANAGE_USERS]}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={editForm.email}
                onChange={(e) => setEditForm({...editForm, email: e.target.value})}
              />
            </Form.Group>
            {userProfile?.permissions?.[PERMISSIONS.MANAGE_USERS] && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    value={editForm.role}
                    onChange={(e) => setEditForm({...editForm, role: e.target.value})}
                  >
                    <option value="user">User</option>
                    <option value="editor">Editor</option>
                    <option value="admin">Admin</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="user-status-switch"
                    label="Active Status"
                    checked={editForm.isActive}
                    onChange={(e) => setEditForm({...editForm, isActive: e.target.checked})}
                  />
                </Form.Group>
              </>
            )}
            <div className="d-flex justify-content-end">
              <Button variant="secondary" className="me-2" onClick={() => setShowUserModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Dashboard;