import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        email: '',
        message: ''
    });
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('submitting');
        try {
            const response = await fetch('https://formspree.io/f/xqazrazd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setSubmitStatus('success');
                setFormData({ name: '', subject: '', email: '', message: '' });
            } else {
                throw new Error('Server response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmitStatus('error');
        }
    };

    return (
        <div style={{
            backgroundColor: '#121212',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem 0'
        }}>
            <Container>
                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                    <h2 style={{ color: '#FFD700', textAlign: 'center', marginBottom: '2rem' }}>Contact Me</h2>
                    {submitStatus === 'success' && (
                        <Alert variant="success" className="mb-3">
                            Thank you for your message! We'll get back to you soon.
                        </Alert>
                    )}
                    {submitStatus === 'error' && (
                        <Alert variant="danger" className="mb-3">
                            An error occurred. Please try again later.
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit} style={{
                        backgroundColor: '#1E1E1E',
                        padding: '2rem',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="name" style={{ color: '#B0B0B0' }}>Your Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                style={inputStyle}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="subject" style={{ color: '#B0B0B0' }}>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                id="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                style={inputStyle}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="email" style={{ color: '#B0B0B0' }}>Email</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                style={inputStyle}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="message" style={{ color: '#B0B0B0' }}>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="message"
                                value={formData.message}
                                onChange={handleChange}
                                style={{ ...inputStyle, minHeight: '120px' }}
                                required
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            size="lg"
                            className="w-100"
                            type="submit"
                            style={{
                                backgroundColor: '#FFD700',
                                borderColor: '#FFD700',
                                color: '#121212',
                                fontWeight: 'bold'
                            }}
                            disabled={submitStatus === 'submitting'}
                        >
                            {submitStatus === 'submitting' ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Form>
                </div>
            </Container>
        </div>
    );
};

const inputStyle = {
    backgroundColor: '#333',
    border: '1px solid #444',
    color: '#E0E0E0',
    borderRadius: '4px'
};

export default ContactPage;