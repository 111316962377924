import React from 'react';
import { Mail, Linkedin, Github } from 'lucide-react';

export const FooterBar = () => {
    return (
        <footer style={{
            backgroundColor: '#000000',
            color: '#a0a0a0',
            padding: '2rem 0'
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5 className="mb-3" style={{ color: '#e0e0e0' }}>Jordan Carter</h5>
                        <p>Developer</p>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5 className="mb-3" style={{ color: '#e0e0e0' }}>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#" style={{ color: '#a0a0a0', textDecoration: 'none' }}>Home</a></li>
                            <li><a href="#" style={{ color: '#a0a0a0', textDecoration: 'none' }}>Projects</a></li>
                            <li><a href="#" style={{ color: '#a0a0a0', textDecoration: 'none' }}>Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-3" style={{ color: '#e0e0e0' }}>Connect</h5>
                        <div className="d-flex">
                            <a href="mailto:jordan@nsa.icu" style={{ color: '#a0a0a0' }} className="me-3" target="_blank" rel="noopener noreferrer">
                                <Mail size={24} />
                            </a>
                            <a href="https://www.linkedin.com/in/jordan-carter-4040742b3/" style={{ color: '#a0a0a0' }} className="me-3" target="_blank" rel="noopener noreferrer">
                                <Linkedin size={24} />
                            </a>
                            <a href="https://github.com/1-TB" style={{ color: '#a0a0a0' }} className="me-3" target="_blank" rel="noopener noreferrer">
                                <Github size={24} />
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="my-4" style={{ borderColor: '#2a2a2a' }} />
                <div className="text-center">
                    <small style={{ color: '#808080' }}>© {new Date().getFullYear()} Jordan Carter. All rights reserved.</small>
                </div>
            </div>
        </footer>
    );
};