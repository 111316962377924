import React from 'react';

export const AboutMeSection = () => {
    return (
        <section className="portfolio-block website gradient" style={{
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(12, 42, 71, 0.9))',
            padding: '4rem 0',
            position: 'relative',
            overflow: 'hidden'
        }}>

            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'200\' height=\'200\'%3E%3Cpath d=\'M 0 0 L 200 0 L 200 200 L 0 200 Z\' fill=\'none\' stroke=\'none\' /%3E%3Ccircle cx=\'50\' cy=\'50\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'150\' cy=\'100\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'100\' cy=\'150\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'25\' cy=\'175\' r=\'1\' fill=\'%23FFD700\' /%3E%3Ccircle cx=\'175\' cy=\'25\' r=\'1\' fill=\'%23FFD700\' /%3E%3C/svg%3E")',
                opacity: 0.2
            }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <h3 className="text-center mb-4" style={{
                            color: '#FFD700',
                            fontWeight: 'bold',
                            fontSize: '2.5rem',
                            textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
                        }}>About Me</h3>
                        <p className="text-center" style={{
                            color: '#FFA500',
                            fontSize: '1.1rem',
                            lineHeight: '1.8',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                            background: 'rgba(0, 0, 0, 0.6)',
                            padding: '20px',
                            borderRadius: '10px'
                        }}>
                            My name is Jordan, and I am deeply passionate about technology, cybersecurity, programming, data privacy, and automotive innovations. With a strong work ethic and a readiness to tackle new challenges, I am actively seeking opportunities to apply my skills and knowledge. For a detailed overview of my professional background and accomplishments, I invite you to visit my LinkedIn profile. Currently, I am furthering my education at Ozarks Technical Community College, where I am dedicated to expanding my expertise in these areas.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};